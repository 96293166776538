<template>
  <div class="content">
    <div class="container">
      <div class="row align-items-stretch no-gutters contact-wrap">
        <div class="col-lg-8 col-md-8 col-sm-12">
          <div class="form h-100">
            <h3>Please fill in the contact form below and we will reach out to your question as soon as possible.</h3>
<!--            <form class="mb-5" method="post" id="contactForm" name="contactForm">-->
              <div class="row">
                <div class="col-md-6 form-group mb-5">
                  <label for="" class="col-form-label">Name *</label>
                  <input type="text" class="form-control" name="name" id="name" placeholder="Your name">
                </div>
                <div class="col-md-6 form-group mb-5">
                  <label for="" class="col-form-label">Email *</label>
                  <input type="text" class="form-control" name="email" id="email"  placeholder="Your email">
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 form-group mb-5">
                  <label for="" class="col-form-label">Phone</label>
                  <input type="text" class="form-control" name="phone" id="phone"  placeholder="Phone #">
                </div>
                <div class="col-md-6 form-group mb-5">
                  <label for="" class="col-form-label">Company</label>
                  <input type="text" class="form-control" name="company" id="company"  placeholder="Company  name">
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 form-group mb-5">
                  <label for="message" class="col-form-label">Message *</label>
                  <textarea class="form-control" name="message" id="message" cols="30" rows="4"  placeholder="Write your message"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn btn-primary">SEND MESSAGE</button>
                </div>
              </div>
<!--            </form>-->

            <div id="form-message-warning mt-4"></div>
            <div id="form-message-success">
              Your message was sent, thank you!
            </div>

          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="contact-info h-100">
            <h3>CONTACT US</h3>
            <p class="mb-5 text-white font-weight-bold">Get in touch and let us know how we can help.</p>
            <ul class="list-unstyled">
              <li class="d-flex">
                <span class="wrap-icon icon-room mr-3"></span>
                <span class="text text-white font-weight-bold">Address</span>
              </li>
              <li class="d-flex">
                <i class="material-icons mr-3">signpost</i>
                <span class="text text-white font-weight-bolder">Street: Avenida Samuel Lewis Y Calle 54E</span>
              </li>
              <li class="d-flex">
                <i class="material-icons mr-3">location_city</i>
                <span class="text text-white font-weight-bolder">City: Panama-City</span>
              </li>
              <li class="d-flex">
                <i class="material-icons mr-3">receipt_long</i>
                <span class="text text-white font-weight-bolder">NIT (VAT nr.): 155706457-2-2021 DV 71</span>
              </li>
              <li class="d-flex">
                <i class="material-icons mr-3">mail</i>
                <span class="text text-white font-weight-bolder">Email: support@tvsgroupinternational.com</span>
              </li>
              <li class="d-flex">
                <i class="material-icons mr-3">phone</i>
                <span class="text text-white font-weight-bolder">Phone: +507-3948712</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    showAlert: false,
    form: {
      name: null,
      email: null,
      phone: null,
      company: null,
      message: null
    }
  })
}
</script>

<style scoped lang="scss">
body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  line-height: 1.9;
  color: #8c8c8c;
  position: relative; }
body:before {
  z-index: -1;
  position: absolute;
  height: 50vh;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  background: #8bbabb; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", sans-serif;
  color: #000; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
a, a:hover {
  text-decoration: none !important; }

.text-black {
  color: #000; }

.content {
  padding: 7rem 0; }

.heading {
  font-size: 2.5rem;
  font-weight: 900; }

.form-control {
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  background: none; }
.form-control:active, .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000; }

.col-form-label {
  color: #000;
  font-size: 13px; }

.btn, .form-control, .custom-select {
  height: 45px; }

.custom-select:active, .custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000; }

.btn {
  border: none;
  border-radius: 0;
  font-size: 12px;
  letter-spacing: .2rem;
  text-transform: uppercase; }
.btn.btn-primary {
  background: black;
  color: #fff;
  padding: 15px 20px; }
.btn:hover {
  color: #fff; }
.btn:active, .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.contact-wrap {
  -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2); }
.contact-wrap .col-form-label {
  font-size: 14px;
  color: #b3b3b3;
  margin: 0 0 10px 0;
  display: inline-block;
  padding: 0; }
.contact-wrap .form, .contact-wrap .contact-info {
  padding: 40px; }
.contact-wrap .contact-info {
  color: rgba(255, 255, 255, 0.5); }
.contact-wrap .contact-info ul li {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.5); }
.contact-wrap .contact-info ul li .wrap-icon {
  font-size: 20px;
  color: #fff;
  margin-top: 5px; }
.contact-wrap .form {
  background: #fff; }
.contact-wrap .form h3 {
  color: black;
  font-size: 20px;
  margin-bottom: 30px; }
.contact-wrap .contact-info {
  background: black; }
.contact-wrap .contact-info h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px; }

label.error {
  font-size: 12px;
  color: red; }

#message {
  resize: vertical; }

#form-message-warning, #form-message-success {
  display: none; }

#form-message-warning {
  color: #B90B0B; }

#form-message-success {
  color: #55A44E;
  font-size: 18px;
  font-weight: bold; }

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000; }

</style>
